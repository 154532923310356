import React, { useEffect, useState, useCallback,  } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, Col, Row, Button } from "reactstrap";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import UpdateProfileModal from "./UpdateProfileModal";
import { useProfile } from "../../components/Hooks/UserHooks";
import { Navigate } from "react-router-dom";
import { userimg } from "../../images";
import { onUpdateUser } from "../../slices/users/thunk";
import { useDispatch, useSelector } from "react-redux";
import {  ToastContainer } from "react-toastify";
import { UserPosts } from "../../slices/thunks";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import MetaData from "../../components/MetaData";
const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile, loading, token } = useProfile();
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);

  const { userPosts, isUserPostSuccess } = useSelector((state) => ({
    userPosts: state.Posts.userPosts,
    isUserPostSuccess: state.Posts.isUserPostSuccess,
  }));

  useEffect(() => {
    if (userPosts && !userPosts.length) {
      dispatch(UserPosts(userProfile?.user?.id));
    }
  }, []);
  // console.log(userPosts);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: (userProfile && userProfile?.user?.fullName) || "",
      email: (userProfile && userProfile?.user?.email) || "",
      userImg: (userProfile && userProfile?.user?.userImg) || userimg,
      linkedinUrl: (userProfile && userProfile?.user?.linkedinUrl) || "",
      githubUrl: (userProfile && userProfile?.user?.githubUrl) || "",
      description: (userProfile && userProfile?.user?.description) || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Full Name"),
    }),
    onSubmit: (values) => {
      const updateUser = {
        id: userProfile ? userProfile?.user?._id : 0,
        userImg: values.userImg,
        fullName: values.fullName,
        email: userProfile?.user?.email,
        linkedinUrl: values.linkedinUrl,
        githubUrl: values.githubUrl,
        description: values.description,
      };

      // update Company
      dispatch(onUpdateUser(updateUser));
      validation.resetForm();

      toggle();
    },
  });

  if (!userProfile && loading && !token) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  return (
    <>
       <MetaData metaTitle={"Profile"} metaDescription={"User profile information"}/>
      <div className="page_body">
        <ToastContainer closeButton={false} />
        <div className="container mt-5">
          <Row>
            <Col lg={9}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-4">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">
                        {userProfile?.user?.fullName}
                      </h5>
                    </div>
                  </div>
                  {isUserPostSuccess ? (
                    <>
                      {userPosts?.length === 0 ? (
                        <>
                          <div className="text-center text-muted">
                            No Record To Show.!
                          </div>
                        </>
                      ) : (
                        userPosts?.map((item, key) => {
                          return (
                            <>
                              <Card key={key}>
                                <CardBody>
                                  <h6 className="card-title">{item?.title}</h6>

                                  <p
                                    className="card-text text-muted mb-0"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.descriptionOne,
                                    }}
                                  ></p>
                                </CardBody>
                                <div className="card-footer">
                                  <Link
                                    to={`/blog-detail/${item.id}`}
                                    state={item}
                                    className="link-success float-end d-flex justify-content-center align-items-center"
                                  >
                                    Read More{" "}
                                    <i className="ri-arrow-right-s-line align-middle ms-1 lh-1"></i>
                                  </Link>
                                  <p className="text-muted mb-0">
                                    {item?.createdDate &&
                                      new Date(item?.createdDate)
                                        .toISOString()
                                        .split("T")[0]}
                                  </p>
                                </div>
                              </Card>
                            </>
                          );
                        })
                      )}
                    </>
                  ) : (
                    <Loader />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block  mb-4">
                      <img
                        // src={avatar3}
                        src={
                          userProfile?.user?.userImg === "1" ||
                          userProfile?.user?.userImg === ""
                            ? userimg
                            : userProfile?.user?.userImg
                        }
                        className="rounded-circle avatar-md img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                    </div>
                    <h5 className="fs-16 mb-1">
                      {userProfile?.user?.fullName}
                    </h5>
                    <p className="text-muted mb-0">
                      {userProfile?.user?.email}
                    </p>

                    <div className="flex-shrink-0 my-2">
                      <Button
                        size="sm"
                        color="light"
                        onClick={() => {
                          setIsEdit(false);
                          toggle();
                        }}
                      >
                        <i className="ri-edit-box-line "></i> Edit Profile
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5 className="card-title mb-4">Portfolio</h5>
                  <div className="d-flex flex-wrap gap-2">
                    {userProfile?.user?.github && (
                      <div>
                        <Link
                          to={userProfile?.user?.github}
                          className="avatar-xs d-block"
                          style={{ textDecoration: "none" }}
                        >
                          <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                            <i className="ri-github-fill"></i>
                          </span>
                        </Link>
                      </div>
                    )}
                    {userProfile?.user?.linkedinUrl && (
                      <div>
                        <Link
                          to={userProfile?.user?.linkedinUrl}
                          className="avatar-xs d-block"
                          style={{ textDecoration: "none" }}
                        >
                          <span className="avatar-title rounded-circle fs-16 bg-primary">
                            <i className="ri-linkedin-fill"></i>
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <UpdateProfileModal
            modal={modal}
            toggle={toggle}
            validation={validation}
            setModal={setModal}
          />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
