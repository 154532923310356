//API ENDPOINTS
//LOGIN
export const USER_LOGIN = "soft/Login";

//REGISTER
export const USER_REGISTER = "soft/create";
//Users not using
export const CREATE_USER = "soft/create-user";
export const UPDATE_USER = "soft/single-user";

//Posts
export const ALL_POSTS = "soft/all";
export const CREATE_POST = "soft/create";
export const DELETE_POST = "soft/single";
export const UPDATE_POST = "soft/single";
export const ALL_RECENT_APPROVED_POSTS = "Account/GetRecentApprovedPost";
export const GET_POST_BY_ID = "Account/GetPostBlogBySlugId";
export const GET_USER_POST_BY_ID = "soft/blog-by-userid";
export const GET_ALL_CATEGORIES = "soft/allCategories";

// Messages / Comments / Reply

export const CREATE_COMMENT = "Account/SaveComment";
// export const GET_COMMENT_BYPOST_ID = "Account/GetAllCommentByPostId"
export const GET_COMMENT_BYPOST_ID = "Account/GetAllCommentBySlugId";

export const CREATE_REPLY = "Account/SaveCommentReply";
// export const GET_REPLIES_BYPOST_ID = "Account/GetAllReplyByPostId"
export const GET_REPLIES_BYPOST_ID = "Account/GetAllReplyBySlugId";

// CONTACT US / SUBSCRIBE
export const CONTACT_US = "soft/contact-us";
