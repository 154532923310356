import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCategories,
  getAllPosts,
  createNewPost,
  onUpdatePost,
  PostById,
  getRecentApprovedPosts,
  UserPosts
} from "./thunk";
export const initialState = {
  posts: [],
  isCreated: false,
  isPostSuccess: false,
 
  recentPendingPosts: [],
  isRecentPendingPostSuccess: false,
  postById: {},
  isPostByIdSuccess: false,
  recentApprovedPost: [],
  isRecentApprovedPostSuccess:false,
  userPosts:[],
  isUserPostSuccess:false,
  isLoading: false,
  error: "",
  categories:[],
  isCategorySuccess:false,
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    clearRecentPosts: (state) => {
      state.recentPosts = [];
      state.isRecentSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state, action) => {
        state.isLoading = true;
        state.isPostSuccess = false;
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts = action.payload?.posts;
        state.isPostSuccess = true;
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isPostSuccess = false;
      })
      .addCase(createNewPost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createNewPost.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoading = false;
        if (action?.payload && action?.payload?.post) {
          state.posts = [...state.posts, action?.payload?.post];
        }
      })
      .addCase(createNewPost.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(onUpdatePost.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onUpdatePost.fulfilled, (state, action) => {
        // const { obj } = action.payload;

        state.isLoading = false;
        state.posts = state.posts.map((post) => {
          return post._id === action.payload?.obj?.id
            ? { ...post, ...action?.payload?.obj }
            : post
        }
        );

      })
      .addCase(onUpdatePost.rejected, (state, action) => {
        state.isLoading = false;
      })
    

      // post by id
      .addCase(PostById.pending, (state, action) => {
        state.isLoading = true;
        state.isPostByIdSuccess = false;
      })
      .addCase(PostById.fulfilled, (state, action) => {
        // const { obj } = action.payload;

        state.isLoading = false;
        state.isPostByIdSuccess = true;
        state.postById = action.payload;
      })
      .addCase(PostById.rejected, (state, action) => {
        state.isLoading = false;
        state.isPostByIdSuccess = false;
      })

      // recent Approved posts
      .addCase(getRecentApprovedPosts.pending, (state, action) => {
        state.isLoading = true;
        state.isRecentApprovedPostSuccess = false;
      })
      .addCase(getRecentApprovedPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recentApprovedPost = action.payload?.posts;
        state.isRecentApprovedPostSuccess = true;
      })
      .addCase(getRecentApprovedPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isRecentApprovedPostSuccess = false;
      })
      // get post by id
      .addCase(UserPosts.pending, (state, action) => {
        state.isLoading = true;
        state.isUserPostSuccess = false;
      })
      .addCase(UserPosts.fulfilled, (state, action) => {
        // const { obj } = action.payload;
        state.isLoading = false;
        state.isUserPostSuccess = true;
        state.userPosts = action.payload?.items;
      })
      .addCase(UserPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isUserPostSuccess = false;
      })

      // Categories
      .addCase(getAllCategories.pending, (state, action) => {
        state.isLoading = true;
        state.isCategorySuccess = false;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload?.items;
        state.isCategorySuccess = true;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isCategorySuccess = false;
      })

  },
});
export const { clearRecentPosts } = postSlice.actions;
export default postSlice.reducer;
