/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import "../sass/contact.scss";
import {
  Input,
  Label,
  Form,
  FormFeedback,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import TextSlicer from "../../components/TextSlicer";
// import AnimatePage from "../../components/AnimatePage";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import MetaData from "../../components/MetaData";
import { toast, ToastContainer } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();

const description = `Thank you for considering Softorithm (SMC-Private) Limited for your digital needs! We are excited to hear from you and discuss how our diverse range of services can elevate your online presence. Whether you're looking for cutting-edge web development, eye-catching graphic design, intuitive UI/UX solutions, WordPress development, SEO strategies to boost your visibility, engaging social media marketing, targeted Google Ads campaigns, captivating 2D and 3D animations, or the creation of powerful online tools, we've got you covered.`;

const description3 = `If you prefer, you can also reach out to us by filling out this form. Our team will get back to you as soon as possible.`;

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Your Full Name"),
      email: Yup.string().required("Please Enter Your Email"),
      message: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: async (values) => {
      const id = toast.loading("Sending mail...");
      try {
        setLoading(true);
        const res = await api.create(
          `${process.env.REACT_APP_COMMON_URL}soft/contact-us`,
          {
            name: values.fullName,
            email: values.email,
            message: values.message,
          }
        );

        if (res.status) {
          toast.update(id, {
            render: res.message,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          toast.update(id, {
            render: res.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
        validation.resetForm();
        setLoading(false);
      } catch (error) {
        toast.update(id, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }

      setTimeout(() => {
        toast.dismiss(id);
      }, 2000);

      validation.resetForm();
      setLoading(false);
    },
  });

  return (
    <>
      <MetaData
        metaTitle={"Contact Us"}
        metaDescription={
          "Thank you for considering Softorithm (SMC-Private) Limited for your digital needs! We are excited to hear from you and discuss how our diverse range of services can elevate your online presence."
        }
      />
      {/* <AnimatePage> */}
      <div className="page_body">
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about_rgt_cont">
                <h2 className="text-center">Contact US</h2>

                <TextSlicer text={description} />
              </div>
            </div>
          </div>
          <div
            className="contact_uss"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="row contact_ordering  " style={{ width: "60%" }}>
              <ToastContainer closeButton={false} />
              <div className="contact_right_p">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label htmlFor="fullName" className="form-label">
                      Full Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="fullName"
                      type="text"
                      placeholder="Enter Full Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fullName || ""}
                      invalid={
                        validation.touched.fullName &&
                        validation.errors.fullName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.fullName &&
                    validation.errors.fullName ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.fullName}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="useremail" className="form-label">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter email address"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.email}</div>
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <Label htmlFor="message" className="form-label">
                      Message <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="message"
                      type="textarea"
                      placeholder="Enter Message"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.message || ""}
                      invalid={
                        validation.touched.message && validation.errors.message
                          ? true
                          : false
                      }
                    />
                    {validation.touched.message && validation.errors.message ? (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.message}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-success w-100"
                      type="submit"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          Loading...{" "}
                        </Spinner>
                      ) : null}
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <TextSlicer text={description3} />
          </div>
        </div>
      </div>
      <footer className="p-4 mt-4 bg-dark position-relative services_footer_tool">
        <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={12} className="text-center text-lg-end">
              <div className="connect_container">
                <ul className="social_icons mt-1">
                  <li>
                    <Link to="instagram.com/softorithm" className="icon_link">
                      <i className="ri-instagram-line instagram_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="facebook.com/softorithm" className="icon_link">
                      <i className="ri-facebook-box-fill facebook_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/@softorithm"
                      className="icon_link"
                    >
                      <i className="ri-youtube-line youtube_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.tiktok.com/@softorithm"
                      className="icon_link"
                    >
                      <i className="ri-tiktok-fill tiktok_icon"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* </AnimatePage> */}
    </>
  );
};

export default ContactUs;
