import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  errorMsg: "", // for error message
  isLoading: false,
  isUserLogout: false,
  error: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = false;
    },

    apiError: (state, action) => {
      state.error = true;
      state.isUserLogout = false;
      state.isLoading = false;
      state.errorMsg = "Invalid email or password";
    },

    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = false;
    },

    logoutUserSuccess: (state, action) => {
      state.isUserLogout = true;
      state.user = {};
    },

    reset_login_flag: (state, action) => {
      state.error = false;
      state.isLoading = false;
      state.errorMsg = "";
    },
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  loginRequest,
} = loginSlice.actions;

export default loginSlice.reducer;
