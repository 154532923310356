import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";

// export const getAllUsers = createAsyncThunk("user/getAllUsers", async () => {
//   try {
//     return await services.getUsers();
//   } catch (error) {}
// });

export const createNewUser = createAsyncThunk(
  "user/createNewUser",
  async (obj) => {
    try {
      //console.log(obj)
      return await services.createUser(obj);
    } catch (error) {}
  }
);

// export const deleteUser = createAsyncThunk("user/deleteUser", async (obj) => {
//   try {
//     //console.log(obj)
//     return await services.onDeleteUser(obj);
//   } catch (error) {}
// });

export const onUpdateUser = createAsyncThunk(
  "user/onUpdateUser",
  async (obj) => {
    try {
      return await services.updateUser(obj);
    } catch (error) {}
  }
);
